import React from 'react';

function AboutMePage() {

    return(
        <div>

        </div>
    )
}

export default AboutMePage;